import React from 'react'
import ReactDOM from 'react-dom'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Image from '../components/image'
import Ionicon from 'react-ionicons'
import MenuButton from '../components/menuButton'
import ReactTypingEffect from 'react-typing-effect';
import Particles from 'react-particles-js';
import CustomParticles from '../components/CustomParticles.js';

//import '@fortawesome/fontawesome-free/css/font-awesome.min.css';


const IndexPage = () => (
  <Layout>
  <CustomParticles/>
    <div className="RightMenu" id="mySidenav">

      <div className="logoHolder">
        < div className="LogoImg" align="center">
          <img src={require('../images/Logo.png')} width="80"/>
        </div>
        <p className="MenuTitle">Erick Hernández</p>
        <p className="MenuSubtitle">Interaction Designer · Developer</p>
        <a href="#" ><Ionicon className ="CloseButton" icon="md-close" onClick={() => document.getElementById("mySidenav").style.width = "0"} color="#F7F7F7"/></a>
      </div>

        <div className="menuContent">
          <nav><Link to="/"><Ionicon icon ="ios-home" color="#F7F7F7" fontSize="23px"/>&ensp; HOME</Link></nav>
          <nav><Link to="/portfolio"><Ionicon icon ="ios-briefcase-outline" color="#F7F7F7" fontSize="20px"/>&ensp;PORTFOLIO</Link></nav>
          <nav><Link to="/resume"><Ionicon icon ="ios-paper-outline" color="#F7F7F7" fontSize="20px"/>&ensp;RESUME</Link></nav>
          <nav><Link to="/about"><Ionicon icon ="ios-contact-outline" color="#F7F7F7" fontSize="20px"/>&ensp;ABOUT</Link></nav>
          <nav><Link to="/contact"><Ionicon icon ="ios-send-outline" color="#F7F7F7" fontSize="20px"/>&ensp;CONTACT</Link></nav>

        </div>

        <div className="bottomgridHolder">
          <div className="bottomGrid">
            <a className="email" href="mailto:hola@erickh.info" align="center">hola@erickh.info</a>
              <div className="grid-container">
              <div className="grid-item"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="#f7f7f7" onClick={() => window.open('https://www.behance.net/ergihervi')} style={{cursor:'pointer'}}><path d="M232 237.2c31.8-15.2 48.4-38.2 48.4-74 0-70.6-52.6-87.8-113.3-87.8H0v354.4h171.8c64.4 0 124.9-30.9 124.9-102.9 0-44.5-21.1-77.4-64.7-89.7zM77.9 135.9H151c28.1 0 53.4 7.9 53.4 40.5 0 30.1-19.7 42.2-47.5 42.2h-79v-82.7zm83.3 233.7H77.9V272h84.9c34.3 0 56 14.3 56 50.6 0 35.8-25.9 47-57.6 47zm358.5-240.7H376V94h143.7v34.9zM576 305.2c0-75.9-44.4-139.2-124.9-139.2-78.2 0-131.3 58.8-131.3 135.8 0 79.9 50.3 134.7 131.3 134.7 61.3 0 101-27.6 120.1-86.3H509c-6.7 21.9-34.3 33.5-55.7 33.5-41.3 0-63-24.2-63-65.3h185.1c.3-4.2.6-8.7.6-13.2zM390.4 274c2.3-33.7 24.7-54.8 58.5-54.8 35.4 0 53.2 20.8 56.2 54.8H390.4z"/></svg></div>
                <div className="grid-item"><Ionicon icon="logo-youtube"  color="#F7F7F7" fontSize="20px" onClick={() => window.open('https://www.youtube.com/user/ergihervi/featured')} style={{cursor:'pointer'}}/></div>
                <div className="grid-item"><Ionicon icon="logo-vimeo"  color="#F7F7F7" fontSize="20px" onClick={() => window.open('https://vimeo.com/ergihervi')} style={{cursor:'pointer'}}/></div>
                <div className="grid-item"><Ionicon icon="logo-linkedin"  color="#F7F7F7" fontSize="20px" onClick={() => window.open('https://www.linkedin.com/in/ergihervi/')} style={{cursor:'pointer'}}/></div>
              </div>
          </div>
        </div>

    </div>

    <div className="MainContent">


    <MenuButton action={() => document.getElementById("mySidenav").style.width = "250px"}/>



      <div className="WelcomeText">
        <h1>Hej people </h1>
        <div className="wrapper2">
          <div>I'm</div>
          <div><ReactTypingEffect className="TypingText" text= {["an Interaction Designer.", "also a developer :D"]} typingDelay={0} speed={90} eraseDelay={1500}/></div>
          </div>
      </div>
      <div className="layerToMask"></div>

      <div className="pageFooter">
        <p>Hecho con ♥ en suecia. © 2019 Erick Hernández. All rights reserved.</p>
      </div>


    </div>

  </Layout>
)

export default IndexPage
